// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { ComponentConfiguration, useComponentMetrics } from "@amzn/awsui-component-toolkit/internal";

import { PACKAGE_SOURCE, PACKAGE_VERSION, THEME } from "../environment";
import { useVisualRefresh } from "./use-visual-refresh";

export function useTelemetry(componentName: string, config?: ComponentConfiguration) {
  const theme = useVisualRefresh() ? "vr" : THEME;
  useComponentMetrics(componentName, { packageSource: PACKAGE_SOURCE, packageVersion: PACKAGE_VERSION, theme }, config);
}
